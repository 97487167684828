<template>
  <app-overlay loading-variable="sidebarOverlay">
    <validation-observer ref="newContactForm">
      <app-input v-model="data.name" name="Firma Unvanı" label="* Firma Unvanı:" placeholder="Firma Unvanı" rules="required" />
      <app-mask-input
        v-model="data.tax_number"
        input-mask="###########"
        name="Vergi/TC Numarası"
        type="number"
        label="* Vergi/TC Numarası:"
        placeholder="Vergi/TC Numarası"
        rules="required|min:10"
      />
      <app-input v-model="data.tax_office" name="Vergi Dairesi" label="* Vergi Dairesi:" placeholder="Vergi Dairesi" rules="required" />
      <app-mask-input v-model="data.phone" input-mask="#### ### ## ##" name="Telefon" type="number" label="Telefon:" placeholder="Telefon" rules="min:11" />
      <app-input v-model="data.email" type="email" name="E-Posta" label="E-Posta:" placeholder="E-Posta" rules="email" />
      <app-textarea-input v-model="data.address" name="Açık Adres" placeholder="Açık Adres" label="* Açık Adres:" rules="required" rows="3" />
      <app-button @click="createContact" text="Ekle" size="md" icon="PlusIcon" block />
      <hr />
    </validation-observer>
  </app-overlay>
</template>

<script>
import { ValidationObserver } from "vee-validate";
export default {
  components: { ValidationObserver },
  data() {
    return {
      data: {
        name: "",
        tax_number: "",
        tax_office: "",
        address: "",
        phone: "",
        email: "",
      },
    };
  },
  computed: {
    callback() {
      return this.$store.getters.appSidebarItem?.callback;
    },
  },
  methods: {
    setData() {
      return {
        ...this.data,
        phone: this?.data?.phone?.replaceAll(" ", ""),
      };
    },
    createContact() {
      this.$validate(this.$refs.newContactForm, () => {
        this.$axios
          .post("/finance/contacts/new-contact", this.setData(), { loading: "sidebar" })
          .then((result) => {
            this.callback && this.callback();
            this.$emitter.$emit("Notification", { title: "İşlem Başarılı", variant: "success", message: result.data.message ?? null });
            this.$store.commit("cleanAppSidebar");
          })
          .catch((err) => {
            this.$emitter.$emit("Notification", { title: "Hata Oluştu", variant: "danger", message: err.data.message ?? null });
          });
      });
    },
  },
};
</script>

<style></style>
