<template>
  <app-overlay>
    <b-card no-body class="card-revenue-budget border-secondary">
      <b-row class="mx-0">
        <b-col md="9" class="revenue-report-wrapper">
          <div class="d-sm-flex justify-content-between align-items-center mb-3">
            <h4 class="card-title mb-50 mb-sm-0">Genel Durum</h4>
            <div class="d-flex gap-5">
              <b-dropdown :text="default_date_range.label" class="budget-dropdown" variant="outline-secondary">
                <b-dropdown-item @click="onDateRangeChanged(item)" v-for="item in dateRangeOptions" :key="item.key">
                  {{ item.label }}
                </b-dropdown-item>
              </b-dropdown>
              <b-dropdown v-if="default_date_range.key === 'monthly'" :text="dropdown_year" class="budget-dropdown" variant="outline-secondary">
                <b-dropdown-item @click="onYearChange(item)" v-for="item in yearOptions" :key="item">
                  {{ item }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>

          <!-- chart -->
          <vue-apex-charts ref="monthlyPaymentChart" id="revenue-report-chart" type="bar" :options="revenueReport.chartOptions" :series="revenueReport.series" />
        </b-col>

        <b-col md="3" class="budget-wrapper">
          <b-dropdown :text="default_display.label" class="budget-dropdown" variant="outline-secondary">
            <b-dropdown-item @click="onDataDisplayChange(item)" v-for="item in displayOptions" :key="item.key">
              {{ item.label }}
            </b-dropdown-item>
          </b-dropdown>
          <b-card v-for="item in statisticDropdownOptions" class="border-secondary w-100" body-class="d-flex flex-column gap-15 align-items-center" :key="item.key">
            <b-avatar :variant="default_display.variant" :style="{ backgroundColor: default_display.bgColor }" size="55">
              <mdi-icon :icon="default_display.icon" size="30" />
            </b-avatar>
            <div class="truncate">
              <h2 class="mb-25 font-weight-bolder">
                {{ $formatToTL(getStatisticItem(item.key)) }}
              </h2>
              <span>{{ item.subLabel }}</span>
            </div>
          </b-card>

          <b-card class="border-secondary w-100 mb-0" body-class="d-flex flex-column gap-15 align-items-center">
            <b-avatar variant="primary" size="55">
              <mdi-icon icon="CreditCardClockOutline" size="30" />
            </b-avatar>
            <div class="truncate">
              <h2 class="mb-25 font-weight-bolder">
                {{ $formatToTL(total_receivable_difference) }}
              </h2>
              <span>Toplam Alacak Farkı</span>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </app-overlay>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { formatTotTL } from "@/global-prototypes";

export default {
  components: {
    VueApexCharts,
  },
  data() {
    const thisYear = new Date().getFullYear();
    const yearOptions = [];

    for (let i = thisYear; i >= 2023; i--) {
      yearOptions.push(i.toString());
    }

    const default_display = { key: "payment", label: "Tahsilat", icon: "CreditCardCheckOutline", variant: "success" };
    return {
      default_date_range: { key: "monthly", label: "Aylık" },
      dateRangeOptions: [
        { key: "monthly", label: "Aylık" },
        { key: "weekly", label: "Haftalık" },
      ],

      total_receivable_difference: 0,
      default_display,
      trMonthOptions: ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"],

      displayOptions: [
        { ...default_display },
        { key: "invoice", label: "Fatura", icon: "InvoiceTextArrowRightOutline", bgColor: "#008ffb" },
        { key: "expenditure", label: "Gider", icon: "InvoiceTextMultipleOutline", variant: "warning" },
      ],
      thisYear,
      yearOptions,
      monthOptions: ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"],
      statisticDropdownOptions: [
        { label: "Günlük", subLabel: `Günlük (${this.$DateTime.now().toFormat("dd LLLL yyyy", { locale: "tr" })})`, key: "daily" },
        { label: "Haftalık", subLabel: "Haftalık (Son 7 Gün)", key: "weekly" },
        { label: "Aylık", subLabel: "Aylık (Son 30 Gün)", key: "monthly" },
      ],
      data_displays: {
        payment: {
          monthly: 0,
          weekly: 0,
          daily: 0,
        },
        invoice: {
          monthly: 0,
          weekly: 0,
          daily: 0,
        },
        expenditure: {
          monthly: 0,
          weekly: 0,
          daily: 0,
        },
        monthly: null,
        weekly: null,
      },

      dropdown_year: thisYear.toString(),
      revenueReport: {
        series: [
          {
            name: "Fatura",
            key: "invoice",
            data: [],
          },
          {
            name: "Tahsilat",
            key: "payment",
            data: [],
          },
          {
            name: "Gider",
            key: "expenditure",
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            type: "bar",
            height: "100%",
            toolbar: { show: false },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "40%",
              endingShape: "rounded",
            },
          },
          legend: {
            show: true,
            showForSingleSeries: true,
            showForNullSeries: true,
            showForZeroSeries: true,
            position: "top",
            fontSize: "14",
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: false,
            width: 2,
            colors: ["transparent"],
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            labels: { formatter: (val) => formatTotTL(val) },
          },
          fill: {
            opacity: 1,
          },
          tooltip: {
            y: {
              formatter: (item) => formatTotTL(item),
            },
          },
        },
      },
    };
  },
  methods: {
    setChartData() {
      this.revenueReport.series = [
        {
          name: "Fatura",
          key: "invoice",
          data: [],
        },
        {
          name: "Tahsilat",
          key: "payment",
          data: [],
        },
        {
          name: "Gider",
          key: "expenditure",
          data: [],
        },
        {
          name: "Alacak Farkı",
          key: "receivable_difference",
          data: [],
        },
      ];
      if (this.default_date_range?.key === "monthly") {
        Object?.keys(this.monthly).map((key) => {
          if (this.data_displays[key]) {
            this.data_displays[key].monthly = this?.monthly[key]?.monthly?.totalAmount ?? 0;
            this.data_displays[key].weekly = this?.monthly[key]?.weekly?.totalAmount ?? 0;
            this.data_displays[key].daily = this?.monthly[key]?.daily?.totalAmount ?? 0;
          }
          if (this?.monthly[key].eachMonth) {
            const seriesIndex = this.revenueReport.series.map((item) => item.key).indexOf(key);
            this.revenueReport.chartOptions.xaxis.categories = this.trMonthOptions;
            this.monthOptions.forEach((item) => {
              this.revenueReport?.series[seriesIndex]?.data.push(this?.monthly[key].eachMonth[item] ? this?.monthly[key].eachMonth[item] : 0);
            });
          }
        });
      }
      if (this.default_date_range?.key === "weekly") {
        Object?.keys(this.weekly).map((key) => {
          const seriesIndex = this.revenueReport.series.map((item) => item.key).indexOf(key);
          this.revenueReport.chartOptions.xaxis.categories = this.weekly[key]?.map((item) => item?.label);
          this.revenueReport.series[seriesIndex].data = this.weekly[key]?.map((item) => item?.totalAmount ?? 0);
        });
      }

      this.$refs?.monthlyPaymentChart?.updateSeries(this.revenueReport?.series, false, true);
      this.$refs?.monthlyPaymentChart?.updateOptions(this?.revenueReport?.chartOptions);
    },
    getData() {
      this.$axios
        .get("/finance/reports/total-amounts", { params: { year: this.dropdown_year }, loading: "table" })
        .then((result) => {
          this.monthly = result?.data?.monthly;
          this.weekly = result?.data?.weekly;
          this.total_receivable_difference = result?.data?.monthly?.receivable_difference?.total_receivable_difference ?? 0;
          this.setChartData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getStatisticItem(key) {
      return this?.data_displays[this?.default_display?.key][key] ?? 0;
    },
    onYearChange(item) {
      if (this.dropdown_year !== item) {
        this.dropdown_year = item;
        this.getData();
      }
    },
    onDateRangeChanged(item) {
      if (this.default_date_range?.key !== item?.key) {
        this.default_date_range = item;
        this.setChartData();
      }
    },
    onDataDisplayChange(item) {
      this.default_display = item;
    },
  },
  mounted() {
    if (this.$can("read", "finance-reports")) {
      this.getData();
    }
  },
};
</script>
