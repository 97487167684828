<template>
  <app-overlay>
    <db-searchable-table
      @row-clicked="showDetails"
      v-model="filters"
      :items="items"
      :fields="fields"
      :total-rows="totalRows"
      searchable
      default-sort-by="balance"
      @changed="getData"
      hover
      use-route-query
      filterable
    >
      <template #modalbutton>
        <app-button v-if="$can('create', 'finance-contacts')" @click="showNewContactForm" text="Müşteri Ekle" size="md" icon="PlusIcon" />
        <app-button v-if="$can('write', 'finance-contacts')" @click="makeUpdate" text="Paraşüt Güncelle" size="md" icon="RefreshCcwIcon" :loading="parasutUpdate" />
      </template>
      <template #workSafetyService="{ item }">
        <b-badge :variant="item.workSafetyService === 'Aktif' ? 'success' : 'secondary'">
          {{ item.workSafetyService }}
        </b-badge>
      </template>
      <template #filtercollapse>
        <b-row>
          <b-col lg="4">
            <app-date-input v-model="invoiceDateRange" name="Fatura Tarihi" label="Fatura Tarihi:" placeholder="Tarih Seçiniz..." mode="range" />
          </b-col>
          <b-col lg="4">
            <div class="border rounded py-75">
              <h5 class="px-75">İSG Hizmeti</h5>
              <hr class="my-75" />
              <b-form-checkbox-group v-model="wsServiceFilter" class="d-flex gap-10 px-75" :options="wsServiceOptions"></b-form-checkbox-group>
            </div>
          </b-col>
          <b-col lg="4">
            <div class="border rounded py-75">
              <h5 class="px-75">Sütun Seçimi</h5>
              <hr class="my-75" />
              <b-form-checkbox-group v-model="optionalColumns" class="d-flex gap-10 px-75 flex-wrap" :options="columnOptions"></b-form-checkbox-group>
            </div>
          </b-col>
        </b-row>
        <div class="d-flex gap-5 mt-25">
          <app-button @click="getData" icon="FilterIcon" text="Filtrele" size="md" />
          <app-button @click="downloadAsExcel" icon="DownloadCloudIcon" text="Dışa Aktar" size="md" />
        </div>
      </template>
      <template #actions="{ item }">
        <app-dropdown>
          <b-dropdown-item v-if="$can('create', 'finance-payments')" @click="showContactPaymentForm(item)"> Tahsilat Ekle </b-dropdown-item>
          <b-dropdown-item> Arama Yap </b-dropdown-item>
        </app-dropdown>
      </template>
    </db-searchable-table>
  </app-overlay>
</template>

<script>
import ContactPaymentForm from "./ContactPaymentForm.vue";
import NewContactForm from "./NewContactForm.vue";

export default {
  data() {
    return {
      items: [],
      filters: {},
      columnOptions: [
        { text: "Son Ödeme", value: { key: "lastPayment.amount", label: "Son Ödeme", formatToTL: true } },
        { text: "Tehlike Sınıfı", value: { key: "riskState", label: "Tehlike Sınıfı" } },
        { text: "Çalışan Sayısı", value: { key: "employeeCount", label: "Çalışan Sayısı" } },
        { text: "Ortalama Tutarı", value: { key: "amountAverage", label: "Ortalama Tutarı" } },
        { text: "Fatura Tutarı", value: { key: "wsInvoiceTotal.totalAmount", label: "Fatura Tutarı", formatToTL: true, sortable: true } },
        { text: "Gecikme Oranı", value: { key: "invoiceBalanceRatio", label: "Gecikme Oranı" } },
        { text: "Gecikme Faizi", value: { key: "wsInvoiceTotal.total_overdue_interest", label: "Gecikme Faizi", formatToTL: true, sortable: true } },
      ],
      optionalColumns: [{ key: "wsInvoiceTotal.totalAmount", label: "Fatura Tutarı", formatToTL: true, sortable: true }],
      wsServiceOptions: [
        { text: "Aktif", value: "Aktif" },
        { text: "Pasif", value: "Pasif" },
        { text: "Yok", value: "Yok" },
      ],
      wsServiceFilter: [],
      totalRows: 0,
      parasutUpdate: false,
      invoiceDateRange: null,
    };
  },
  computed: {
    fields() {
      return [
        { key: "name", label: "Firma Adı", sortable: true, sortKey: "name", sortDirection: "asc", limit: 20, tdClass: "no-wrap" },
        { key: "workSafetyService", label: "ISG Hizmeti" },
        { key: "balance", label: "Bakiye", formatToTL: true, sortable: true, sortKey: "balance", sortDirection: "desc" },
        ...this.optionalColumns,
        { key: "actions", label: "İşlemler" },
      ];
    },
  },
  methods: {
    getData() {
      const wsServiceFilter = this?.wsServiceFilter?.length ? JSON.stringify({ $in: this.wsServiceFilter }) : undefined;
      this.$axios
        .get("/finance/parasut-companies/list", {
          params: { ...this.filters, invoiceDateRange: this.setDateQuery(this.invoiceDateRange), searchFields: JSON.stringify(["name"]), workSafetyService: wsServiceFilter },
          loading: "table",
        })
        .then((result) => {
          this.totalRows = result.data?.total ?? 0;
          this.items = result.data?.data ?? [];
        })
        .catch((err) => {
          err;
        });
    },
    showDetails(item) {
      this.$router.push({ name: "finance-contact-detail", params: { id: item?.contact_id } });
    },
    showContactPaymentForm(item) {
      this.$showAppSidebar("Tahsilat Ekle", ContactPaymentForm, { data: { ...item }, callback: this.getData });
    },
    showNewContactForm() {
      this.$showAppSidebar("Müşteri Ekle", NewContactForm, { callback: this.getData });
    },
    getStatus() {
      if (this.$can("write", "finance-contacts"))
        this.$axios
          .get("/finance/parasut-companies/parasut-update-status")
          .then((result) => {
            this.parasutUpdate = result.status === 201;
          })
          .catch((err) => {
            this.parasutUpdate = false;
            console.log(err);
          });
    },
    makeUpdate() {
      if (this.$can("write", "finance-contacts")) {
        this.parasutUpdate = true;
        this.$axios
          .post("/finance/parasut-companies/update-from-parasut")
          .then((result) => {
            this.parasutUpdate = result.status === 201;
          })
          .catch((err) => {
            this.parasutUpdate = false;
          });
      }
    },
    downloadAsExcel() {
      const wsServiceFilter = this?.wsServiceFilter?.length ? JSON.stringify({ $in: this.wsServiceFilter }) : undefined;
      this.$axios
        .get("/finance/parasut-companies/export-as-excel", {
          params: {
            invoiceDateRange: this.setDateQuery(this.invoiceDateRange),
            workSafetyService: wsServiceFilter,
            sortType: this?.filters?.sortType,
            sortBy: this?.filters?.sortBy,
          },
          responseType: "blob",
          loading: "table",
        })
        .then((result) => {
          const url = window.URL.createObjectURL(new Blob([result.data], { type: result.headers["content-type"] }));
          const link = document.createElement("a");
          link.href = url;
          link.download = result.headers["Content-Disposition"]?.split("filename=")[1] ?? "firmalar.xlsx";
          link.click();
        })
        .catch((err) => {
          console.log(err);
          this.$emitter.$emit("Notification", { title: "Hata Oluştu", variant: "danger", message: err?.data?.message ?? null });
        });
    },
    setDateQuery(dates) {
      const data = dates?.split(" - ");
      if (data && data.length > 0) return data?.length === 1 ? { date: data[0] } : { startDate: data[0], endDate: data[1] };
    },
  },
  mounted() {
    this.getStatus();

    this.$socket.on("PARASUT_UPDATE", ({ status }) => {
      this.parasutUpdate = status === 201;
    });
  },
  destroyed() {
    this.$socket.off("PARASUT_UPDATE");
  },
};
</script>

<style></style>
