<template>
  <div>
    <b-custom-tabs :tab-index="tabIndex" id="finance-tabs" :tabs="tabs" lazy no-bottom-gap show-back-button />
  </div>
</template>

<script>
import Contacts from "./contacts/Contacts.vue";
import Invoices from "./invoices/Invoices.vue";
import Expenditures from "./expenditures/Expenditures.vue";
import TotalPaymentAmounts from "./reports/TotalPaymentAmounts.vue";
import FinanceSms from "./sms/FinanceSms.vue";
import InvoicePaymentTabs from "./InvoicePayments/InvoicePaymentTabs.vue";
export default {
  props: ["tabIndex"],
  data() {
    return {};
  },
  computed: {
    user() {
      return this.$store.getters?.user;
    },
    tabs() {
      return [
        { title: "Müşteriler", component: Contacts, route: "finance-contacts", resource: "finance-contacts" },
        { title: "Tahsilatlar", component: InvoicePaymentTabs, route: "finance-invoice-payments", resource: "finance-payments" },
        { title: "Faturalar", component: Invoices, route: "finance-invoices", resource: "finance-invoices" },
        { title: "Giderler", component: Expenditures, route: "finance-expenditures", resource: "finance-expenditures" },
        { title: "SMS", component: FinanceSms, route: "finance-sms", resource: "finance-sms" },
        { title: "Raporlar", component: TotalPaymentAmounts, route: "finance-reports", resource: "finance-reports" },
      ];
    },
  },
};
</script>

<style></style>
