<template>
  <app-overlay>
    <db-searchable-table v-model="filters" :items="items" :fields="fields" :total-rows="totalRows" searchable disable-limit @changed="getData">
      <template #description="{ item }">
        <span v-if="item && item.attributes && item.attributes.description">
          {{ item.attributes.description }}
        </span>
        <span v-else> Fiş / Fatura </span>
      </template>
      <template #expenditureType="{ item }">
        {{ getCategory(item) }}
      </template>
    </db-searchable-table>
  </app-overlay>
</template>

<script>
export default {
  data() {
    return {
      items: [],
      categorires: [],
      filters: {},
      totalRows: 0,
      fields: [
        { key: "description", label: "Tür" },
        { key: "attributes.issue_date", label: "Düzenlenme Tarihi", formatDate: true },
        { key: "attributes.total_paid", label: "Ödenen", formatToTL: true },
        { key: "attributes.remaining", label: "Bakiye", formatToTL: true },
      ],
    };
  },
  computed: {
    getCategory() {
      return (item) => {
        const categoryId = item?.relationships?.category?.data?.id;
        if (!categoryId) return "-";
        const category = this.companies.find((item) => item?.id === categoryId);
        if (!category) return "-";
        return category?.attributes?.name;
      };
    },
  },
  methods: {
    getData() {
      this.$axios
        .get("/finance/expenditures", {
          params: this.filters,
          loading: "table",
        })
        .then((result) => {
          this.totalRows = result.data?.meta?.total_count ?? 0;
          this.items = result.data?.data ?? [];
          this.companies = result?.data?.included;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style></style>
